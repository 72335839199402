import Keycloak from 'keycloak-js';
import Settings from '_config/settings'

const _kc = new Keycloak({
  url: Settings.keycloak.authUrl,
  realm: Settings.keycloak.authRealm,
  clientId: Settings.keycloak.authClientId
})

const initKeycloak = (onAuthenticatedCallback, onUnAuthenticatedCallback) => {
  _kc
    .init({
      onLoad: 'login-required',
      redirectUri: window.location.href
    })
    .then((auth) => {
      if (auth) {
        onAuthenticatedCallback();
      } else {
        onUnAuthenticatedCallback();
      }
    });
};

const username = () => _kc.tokenParsed?.given_name;
const empid = () => _kc.tokenParsed?.preferred_username;
const tokenParsed = () => _kc.tokenParsed;
const token = () => _kc.token;
const hasRealmRole = _kc.hasRealmRole;
const logout = _kc.logout;
const isLoggedIn = () => !!_kc.token;
const AuthService = {
  initKeycloak,
  logout,
  hasRealmRole,
  tokenParsed,
  username,
  empid,
  isLoggedIn,
  token
};
export default AuthService;
