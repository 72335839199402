import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import App from './App'
import GlobalLoader, {
  LoadingView,
  GlobalLoadingView
} from '_components/elements/GlobalLoading'
import store from '_store'
import '_config/i18n'
import '_common/style/frame.scss'
import AuthService from '_utils/AuthService';

if(process.env.USEMOCK === 'true') {
  import("./mock")
}

const Apps = (
  <>
    <GlobalLoadingView ref={globalLoaderRef => {
      GlobalLoader.setGlobalLoader(globalLoaderRef)
    }} />
    <Suspense fallback={<LoadingView/>}>
      <Provider store={store}>
        <App />
      </Provider>
    </Suspense>
  </>
)

AuthService.initKeycloak(
  () => {
    ReactDOM.render(
      Apps,
      document.getElementById('root')
    );
  },
  () => {}
);
