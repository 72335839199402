const settings = {
  serverApiUrl: process.env.REACT_APP_BACKEND_URL || "https://inventory.convect.ai/api/",
  cdnUrl: "",
  keycloak: {
    authUrl: "https://sso.convect.cn/",
    authRealm: "convect",
    authClientId: "datahub-web"
  },
  ...process.env.SETTINGS
};

export default settings;
